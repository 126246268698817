import React, { Component } from "react";
import {
    PDFDownloadLink,
    Document,
    Page,
    View,
    Image,
    PDFViewer
} from "@react-pdf/renderer";
import moment from "moment";
// import Sign from "../../../images/CMS/digital_signature_Mr_Jaiswal.png";
// '../../../images/CMS/sign.png
import ExamplePDF from "./TemplateOne";
import { ACCESS_POINT } from "../../../config";
export default class CmsCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerid: localStorage.getItem("userId"),
            serviceid: localStorage.getItem("currentService"),
            page2: 0,
            show: false
        };
    }
    render() {
        const {
            match: { params }
        } = this.props;
        let username = atob(params.user);
        let quizname = atob(params.quiz);      
        // let date = new Date(atob(params.time));
        // date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        let date = moment(atob(params.time)).format('DD-MM-YYYY');
        let prevalue = Math.round(Number(atob(params.pre)));
        let postvalue = Math.round(Number(atob(params.post)));
        let length = username.length;

        var len = 428;
        if (length < 15) {
            len = 398;
        } else if (length > 20) {
            len = 428;
        }
        return (
            <div style={{ height: "149%", backgroundColor: "#e0c380" }}>

                <PDFViewer style={{ width: "100%" }} width={"100%"}
                    height={1050}
                >
                    <ExamplePDF
                        username={username}
                        date={date}
                        quizname={quizname}
                        prevalue={prevalue}
                        postvalue={postvalue}
                    // Sign={Sign}
                    />
                </PDFViewer>
            </div>
        );
    }
}

import React from "react";
import {
    Page,
    Text,
    Font,
    Document,
    StyleSheet,
    Image,
    View,
    Link
} from "@react-pdf/renderer";
import { ACCESS_POINT } from "../../../config";
import certificate from "../../../../src/images/newCertificate1.png"
import icon from "../../../../src/images/favIcon3.jpg"
import sign from "../../../../src/images/signature.jpg"
const ExamplePDF = ({ username, date, quizname, prevalue, postvalue }) => {
    return (
        <Document>
            <Page>
                <Image style={styles.image} src={certificate} />
                <Image style={styles.icon} src={icon} />
                <Image style={styles.sign} src={sign} />
                <Text style={styles.username}>{username}</Text>
                <View style={styles.view}>
                    <Text style={styles.module}>who has successfully completed the E-LEARNING module</Text>
                    <Text style={styles.quizname}>
                        {quizname} <Text style={{ fontSize: 18 }}>on</Text> {date}
                    </Text>
                </View>
                <Text style={styles.footer}>
                    <Link style={styles.link} src="https://elearning.difuza.com/">
                        e-learning.difuza.com
                    </Link>
                </Text>
                {/* <Text style={[styles.validity, { wrap: 'nowrap' }]}>This certificate is valid for one year from the date of issue.</Text> */}
            </Page>

        </Document>
    );
};
// Font.register({
//     family: "Arial",
//     src: `https://fonts.googleapis.com/css?family=Roboto`
// });
Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
    quizname: {
        fontFamily: "Oswald",
        marginTop: "15px",
        textAlign: "center",
        fontSize: 15,
        fontWeight: "bold",
        // fontFamily:"Oswald"
    },
    // style={[styles.quizname, { textAlign: 'center', fontSize: 15, fontWeight: "bold", fontFamily: 'Oswald' }]}
    view: {
        position: "absolute",
        top: "53%",
        width: "100%",
        left: "-60px"
    },
    username: {
        margin: 5,
        fontSize: 40,
        textAlign: 'center',
        marginRight: "20px",
        alignSelf: "center",
        justifyContent: "center",
        fontFamily: "Times-Roman",
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)',
        position: "absolute",
        top: "53%",
        marginLeft: "28px"
    },
    module: {
        textAlign: 'center',
        marginRight: "70px",
        alignSelf: "center",
        justifyContent: "center",
        fontFamily: "Times-Roman",
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)',
        marginLeft: "80px",
        fontSize: 15
    },
    quizname: {
        textAlign: 'center',
        marginRight: "40px",
        alignSelf: "center",
        justifyContent: "center",
        fontFamily: "Times-Roman",
        transformOrigin: ' 0 0',
        transform: ' rotate(90deg)',
        marginLeft: "10px",
        fontSize: 18,
    },

    image: {
        width: "auto",
        height: "830px",
        // transform: ' rotate(270deg)'
        // top: "25%"
    },
    icon: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        position: "absolute",
        top: "16px",
        right: "14px",
        transform: "rotate(90deg)"
    },
    sign: {
        width: "100px",
        height: "90px",
        bottom: "100px",
        left: "95px",
        transform: "rotate(90deg)",
        position: "absolute"
    },
    footer: {
        position: "absolute",
        top: '50%',
        right: 20,
        fontSize: '17px',
        transform: "rotate(90deg)",
        textAlign: "right",
    },
    link: {
        color: "black",
        cursor: 'pointer'
    },
});

export default ExamplePDF;
